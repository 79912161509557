<template>
  <div>
    <!-- <CRow class="justify-content-center">
      <CCol xl="12">
        <CCard>
          <CCardBody>
              <CRow class="text-center">
                  <CCol col="5">
                      <CButton size="lg" @click="openCreateNewMoodle = true" color="customblue2">สร้างคอร์สใหม่</CButton>&nbsp;
                      <CButton size="lg" @click="openUseOldCourseMoodle = true" color="outline-customblue2">ใช้งานคอร์สเดิม</CButton>
                  </CCol>
              </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow> -->
    <CRow class="pt--2">
        <CCol class="" col="6">
              <CButton size="lg" @click="openCreateNewMoodle = true" color="customblue2">สร้างคอร์สใหม่</CButton>&nbsp;
              <CButton size="lg" @click="openUseOldCourseMoodle = true" color="outline-customblue2">ใช้งานคอร์สเดิม</CButton>
        </CCol>
        <CCol class="text-right" col="6">
          <!-- <CButton size="lg" @click="openUseOldCourseMoodle = true" color="outline-customblue2">Filter<CIcon height=25 name="cil-filter"/></CButton> -->
          <popper
            trigger="clickToToggle"
            :options="{
              placement: 'bottom',
              modifiers: { offset: { offset: '0,0px' } }
            }">
            <div class="popper text-center" style="padding: 10px;">
              <CRow>
                <CCol col=3>
                  <b>แสดงเทอม</b>
                </CCol>
                <CCol col=9>
                  <CInputCheckbox
                    label="เทอมปัจจุบัน"
                    value="showPresentCheckBox"
                    :inline="true"
                    :checked="filterChecked.present"
                    @update:checked="checkFilter"
                  />
                  <CInputCheckbox
                    label="เทอมถัดไป"
                    value="showFutureCheckBox"
                    :inline="true"
                    :checked="filterChecked.future"
                    @update:checked="checkFilter"
                  />
                  <!-- <CRow class="text-left">
                    <CCol col=6>
                      <CInputCheckbox
                        label="ทั้งหมด"
                        value="CheckAkk"
                        :inline="true"
                        :checked="filterChecked.all"
                      />
                    </CCol>
                    <CCol col=6>
                      <CInputCheckbox
                        label="เทอมที่สิ้นสุดแล้ว"
                        value="CheckAkk"
                        :inline="true"
                        :checked="filterChecked.past"
                      />
                    </CCol>
                    <CCol col=6>
                      <CInputCheckbox
                        label="เทอมปัจจุบัน"
                        value="showPresentCheckBox"
                        :inline="true"
                        :checked="filterChecked.present"
                        @update:checked="checkFilter"
                      />
                    </CCol>
                    <CCol col=6>
                    <CInputCheckbox
                      label="เทอมถัดไป"
                      value="showFutureCheckBox"
                      :inline="true"
                      :checked="filterChecked.future"
                      @update:checked="checkFilter"
                    />
                    </CCol>
                  </CRow> -->
                </CCol>
              </CRow>
              
              
            </div>
        
            <button class="btn btn-secondary btn-lg" slot="reference">
              <b>Filter<CIcon height=25 name="cil-filter"/></b>
            </button>
            <!-- <CButton size="lg" @click="openUseOldCourseMoodle = true" color="outline-customblue2">Filter<CIcon height=25 name="cil-filter"/></CButton> -->
          </popper>
            <!-- <CCard>
              <CCardBody>
                <CIcon height=25 name="cil-filter"/>
                <b>Filter:</b>
                <CInputCheckbox
                    label="ทั้งหมด"
                    value="CheckAkk"
                    :inline="true"
                />
                <CInputCheckbox
                    label="อดีต"
                    value="CheckAkk"
                    :inline="true"
                />
                <CInputCheckbox
                    label="ปัจจุบัน"
                    value="CheckAkk"
                    :inline="true"
                />
                <CInputCheckbox
                    label="อนาคต"
                    value="CheckAkk"
                    :inline="true"
                />
              </CCardBody>
            </CCard> -->
        </CCol>
    </CRow>

    <!-- <CreateMoodleModal @afterCreateEvent="callEventListAgain"/> -->
    <CreateNewMoodle  v-if="renderComponent" :openModal="openCreateNewMoodle" @afterCloseModal="openCreateNewMoodle = false" @afterCreateMoodle="callMoodleListAgain" />
    <UseOldCourseMoodle  v-if="renderComponent" :openModal="openUseOldCourseMoodle" @afterCloseModal="openUseOldCourseMoodle = false" @afterCreateMoodle="callMoodleListAgain" />
    <CRow>
        <CardsMoodleSandbox :moodleObject="moodleList[0]" @afterDeleteEvent="callMoodleListAgain"/>
        <CardsMoodle v-bind:key="moodle.id"  v-for='moodle in moodleList' :moodleObject="moodle" @afterDeleteEvent="callMoodleListAgain"/>
    </CRow>
    <CCard>
    </CCard>
  </div>
</template>

<script>
import CardsMoodle from './CardsMoodle'
import CardsMoodleSandbox from './CardsMoodleSandbox'
import CreateNewMoodle from './CreateNewMoodle'
import UseOldCourseMoodle from './UseOldCourseMoodle'
import MoodleApi from "@/services/MoodleApi"
import Swal from 'sweetalert2'
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

export default {
  name: 'Moodlelist',
  components: {
    CardsMoodle,
    CardsMoodleSandbox,
    CreateNewMoodle,
    UseOldCourseMoodle,
    'popper': Popper
  },
  data () {
    return {
      renderComponent: true,
      moodleListAll:[],
      moodleList:[],
      openCreateNewMoodle:false,
      openUseOldCourseMoodle:false,
      reg_Term:process.env.VUE_APP_REG_TERM,
      reg_Year:process.env.VUE_APP_REG_YEAR,
      filterChecked:{
        all:false,
        past:false,
        present:true,
        future:false
      },
      term:{
        presentTerm: "",
        futureTerm: "",
        pastYearForFuterTerm: "",
      }
    }
  },
  created() {
    this.calculateTerm()
    this.getMoodleListByUser(localStorage.getItem('email'))
    // if(localStorage.getItem('Admin') === 'admin'){
    //   console.log("Get Event: Admin active")
    //   if(this.$route.query.geteventof){
    //     if(this.$route.query.geteventof == 'all'){
    //       console.log("Get Event: Get Event All")
    //       // this.getEventListALl()
    //     }else{
    //       console.log("Get Event: Get Event of:",this.$route.query.geteventof)
    //       this.getEventListByOwner(this.$route.query.geteventof)
    //     }
    //   }else{
    //     console.log("Get Event: Get Event of yourself")
    //     this.getEventListByOwner(localStorage.getItem('email'))
    //   }
    // }else{
    //   this.getEventListByOwner(localStorage.getItem('email'))
    // }
  },
  methods: {
    btnClicked (index) {
      this.$router.push({path: `events/${index}`})
    },
    calculateTerm () {
      // this.reg_Term = process.env.VUE_APP_REG_TERM
      // this.reg_Year = process.env.VUE_APP_REG_YEAR
      this.term.presentTerm = `${this.reg_Term}/${this.reg_Year.slice(2, 4)}`
      this.reg_Term = parseInt(this.reg_Term)
      if(this.reg_Term < 3){
          this.term.futureTerm = `${this.reg_Term+1}/${this.reg_Year.slice(2, 4)}`
          this.term.pastYearForFuterTerm = `${this.reg_Term+1}/${parseInt(this.reg_Year.slice(2, 4))-1}`
      }else{
          this.term.futureTerm = `1/${parseInt(this.reg_Year.slice(2, 4))+1}`
          this.term.pastYearForFuterTerm = `1/${parseInt(this.reg_Year.slice(2, 4))}`
      }
    },
    async callMoodleListAgain() {
      console.log('call again')
      this.openCreateNewMoodle = false
      this.openUseOldCourseMoodle = false
      await this.getMoodleListByUser(localStorage.getItem('email'))
      // this.forceRerender()
    },
    async getMoodleListByUser(email){
      console.log("getMoodleListByUser")
      Swal.fire({
        text: 'กำลังโหลด...',
        allowOutsideClick: false
      })
      Swal.showLoading()
      let moodleList = await MoodleApi.getMoodleListByUser(email)
      if(moodleList){
        if(moodleList["status"] === false){
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: 'เกิดข้อผิดพลาดในการดึงข้อมูล Account',
            didDestroy: () => {
              this.$router.push({path: `/troubleshooting`})
            }
          })
        }else{
          this.moodleListAll = moodleList
          this.moodleList = await this.filterOnlyThisTerm(moodleList, this.reg_Term, this.reg_Year)
          if(localStorage.getItem('filterShowFuture') == 'true'){
            this.filterChecked.future = true
            let val = {target:{value:""}}
            val.target.value = "showFutureCheckBox"
            this.checkFilter (true, val)
          }
          if(localStorage.getItem('filterShowPast') == 'true'){
            this.filterChecked.past = true
          }
          console.log("getMoodleListByUser:",moodleList)
          Swal.close()
        }
        // this.getEnrollStatus(email)
      }
    },
    async filterOnlyThisTerm (moodleListAll, term, year) {
      let newMoodleList = []
      for(let i = moodleListAll.length - 1; i >= 0; i--){
        // console.log("filterOnlyThisTerm:",moodleListAll[i])
        // let fullSemester = this.reg_Term+this.reg_Year.slice(2, 4)
        if(moodleListAll[i].idnumber.slice(0, 3) === term+year.slice(2, 4)){
          // console.log(moodleListAll[i].idnumber.slice(0, 3),term+year.slice(2, 4))
          // console.log(moodleListAll[i].idnumber.slice(0, 3) !== term+year.slice(2, 4))
          // moodleListAll.splice(i, 1)
          newMoodleList.push(moodleListAll[i])
        }
      }
      return newMoodleList
    },
    async deleteOnlyThisTerm (moodleList, term, year) {
      // let newMoodleList = []
      for(let i = moodleList.length - 1; i >= 0; i--){
        console.log("filterOnlyThisTerm:",moodleList[i])
        // let fullSemester = this.reg_Term+this.reg_Year.slice(2, 4)
        if(moodleList[i].idnumber.slice(0, 3) === term+year.slice(2, 4)){
          console.log(moodleList[i].idnumber.slice(0, 3),term+year.slice(2, 4))
          console.log(moodleList[i].idnumber.slice(0, 3) !== term+year.slice(2, 4))
          moodleList.splice(i, 1)
          // newMoodleList.push(moodleList[i])
        }
      }
      return moodleList
    },
    async getEnrollStatus (email) {
      let getEnrollStatus = await MoodleApi.getEnrollStatus(email)
      if(getEnrollStatus){
        console.log("getEnrollStatus:",getEnrollStatus)
        this.filterEnrolStatus(getEnrollStatus)
      }
    },
    async filterEnrolStatus (enrollStatusArr) {
      let inArr = []
      for(let i=0;i<enrollStatusArr.length;i++){
        let index = inArr.findIndex(x => x.course_id === enrollStatusArr[i].course_id);
        console.log(index)
        if(index < 0){
          inArr.push(enrollStatusArr[i])
        }else{
          console.log('Duplicate')
          if(parseInt(inArr[index].job_status_id) < parseInt(enrollStatusArr[i].job_status_id)){
            console.log('New')
            inArr.splice(index, 1);
            inArr.push(enrollStatusArr[i])
          }
        }
      }
      console.log(inArr)
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    async checkFilter (event, val) {
      let moodleListAfterAppend = []
      let moodleListAfterDelete = []
      let splitTerm = []
      if(event){
        console.log(val.target.value)
        switch (val.target.value) {
          case "showFutureCheckBox":
            console.log("MoodleListAll:",this.moodleListAll)
            localStorage.setItem('filterShowFuture', true)
            splitTerm = this.term.futureTerm.split("/")
            moodleListAfterAppend = await this.filterOnlyThisTerm(this.moodleListAll, splitTerm[0], "25"+splitTerm[1])
            this.moodleList = this.moodleList.concat(moodleListAfterAppend)
            break
          case "showPresentCheckBox":
            console.log("MoodleListAll:",this.moodleListAll)
            localStorage.setItem('filterShowPresent', true)
            splitTerm = this.term.presentTerm.split("/")
            moodleListAfterAppend = await this.filterOnlyThisTerm(this.moodleListAll, splitTerm[0], "25"+splitTerm[1])
            this.moodleList = this.moodleList.concat(moodleListAfterAppend)
            break
          case "showPastCheckBox":
            
            break
          case "showAllCheckBox":
            
            break
          default:
            break
        }
      }else{
        console.log(val.target.value)
        switch (val.target.value) {
          case "showFutureCheckBox":
            console.log("MoodleList:",this.moodleList)
            localStorage.setItem('filterShowFuture', false)
            splitTerm = this.term.futureTerm.split("/");
            moodleListAfterDelete = await this.deleteOnlyThisTerm(this.moodleList, splitTerm[0], "25"+splitTerm[1])
            this.moodleList = moodleListAfterDelete
            break
          case "showPresentCheckBox":
            console.log("MoodleList:",this.moodleList)
            localStorage.setItem('filterShowPresent', false)
            splitTerm = this.term.presentTerm.split("/");
            moodleListAfterDelete = await this.deleteOnlyThisTerm(this.moodleList, splitTerm[0], "25"+splitTerm[1])
            this.moodleList = moodleListAfterDelete
            break
          case "showPastCheckBox":
            
            break
          case "showAllCheckBox":
            
            break
          default:
            break
        }
      }
    }
  }
}
</script>
