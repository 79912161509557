<template>
<div>
    <CModal
        :title="titleName"
        :show.sync="openCreateModal"
        @update:show="closeModal"
        :centered="true"
    >
        <!-- <CRow>
            <CCol>
                <CProgress class="m-1" :max="10">
                    <CProgressBar :value="2" color="gradient-success"/>
                </CProgress>
            </CCol>
            
        </CRow> -->
        
        <div>
            <div v-if="showSelectCurrentOrFutureTerm">
                <CRow>
                    <CCol sm=12 md=6 xl=6>
                        <center>
                            <CButton size="lg" @click="showCreateCurrentTermFunc" color="outline-customblue2" style="width: 200px;height:200px">
                                <h1><b>{{currentTerm}}</b></h1>
                                สร้างคอร์สเพื่อใช้งานในเทอมปัจจุบัน
                            </CButton>
                        </center>
                    </CCol>
                    <CCol sm=12  md=6 xl=6>
                        <center>
                            <!-- <a href="https://elearning.cmu.ac.th/course/request.php#" target="_blank"> -->
                                <CButton size="lg" @click="showCreateFutureTermFunc" color="outline-customblue2" style="width: 200px;height:200px">
                                <!-- <CButton size="lg" disabled color="outline-customblue2" style="width: 200px;height:200px"> -->
                                    <!-- <CBadge color="danger" class="mfs-auto">ยังไม่เปิดให้บริการ</CBadge> -->
                                    <h1><b>{{futureTerm}}</b></h1>
                                    สร้างคอร์สล่วงหน้าเพื่อใช้งานในเทอมถัดไป
                                    <!-- ขอเปิดคอร์สล่วงหน้าเพื่อใช้งานในเทอมถัดไป -->
                                </CButton>
                            <!-- </a> -->
                        </center>
                    </CCol>
                </CRow>
            </div>
        
            <div v-if="showCreateCurrentTerm">
                <CCollapse :show="!loadRegSuccess">
                    <CRow>
                        <CCol col=12 class="text-center">
                            <div  class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </CCol>
                        <CCol col=12 class="text-center">
                            กำลังดึงข้อมูลจากสำนักทะเบียน
                        </CCol>
                    </CRow>
                </CCollapse>
                <!-- <div v-show="loadRegSuccess"> -->
                <CCollapse :show="loadRegSuccess" >
                    <!-- <CAlert
                        color="warning"
                        closeButton
                        show.sync=true
                    >
                    หากไม่พบกระบวนวิชาในเทอมปัจจุบัน สามารถ<b>คลิกที่นี่</b>เพื่อเปิดคอร์สแบบอ้างข้อมูลในปีก่อนหน้า
                    </CAlert> -->
                    <!-- <CAlert
                        color="warning"
                        closeButton
                        show.sync="true"
                    >
                        <a v-on:click="$router.push({path: 'troubleshooting'})"><b>ไม่พบกระบวนวิชาที่ต้องการ?</b></a>
                    </CAlert> -->
                    <CRow>
                        <CCol>
                            <h5><b>เลือกกระบวนวิชาจากสำนักทะเบียน</b></h5>
                        </CCol>
                    </CRow>
                    <CRow >
                        <CCol class="text-center">
                            <!-- <CInput
                                type="number"
                                prepend="รหัสวิชา 6 หลัก"
                                description="เช่น 261214"
                                required
                                invalid-feedback="คุณกรอกรหัสวิชาไม่ถูกต้อง"
                                :is-valid="moodleCourseCodeValidator"
                            /> -->
                            <CSelect
                                :options="regCourseLists"
                                placeholder="เลือกกระบวนวิชา..."
                                @update:value="regCourseValidator"
                            />
                        </CCol>
                        
                    </CRow>
                    <CAlert
                        color="warning"
                        closeButton
                        :show="true"
                    >
                        ไม่พบกระบวนวิชาที่ต้องการ <a v-on:click="$router.push({path: 'troubleshooting'})"><b>คลิกที่นี่</b></a>
                    </CAlert>
                    <CRow v-show="loadingSections">
                        <CCol col=12 class="text-center">
                            <div  class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </CCol>
                        <CCol col=12 class="text-center">
                            กำลังค้นหาSection
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol class="text-center">
                            <CCollapse :show="cardCollapseSection" >
                                <CCol sm="12">
                                    <CCard body-wrapper>
                                        <CRow>
                                            <CCol sm="12">
                                                <h5><b>เลือก Section</b></h5>
                                            </CCol>
                                        </CRow>
                                        <!-- <span>Section ที่เลือก: {{ sectionCheckedVal }}</span> -->
                                        <!-- <li v-bind:key="name" v-for="(value, name) in CourseSectionLists">
                                            {{ name }}
                                        </li> -->
                                        <CRow>
                                            <CCol sm="12">
                                            <CInputCheckbox
                                                label="เลือกทั้งหมด"
                                                @update:checked="checkAllSectionFunc"
                                                :checked="checkAllSection"
                                                value="CheckAkk"
                                            />
                                            <!-- <CInputCheckbox v-bind:key="name" v-for="(value, name) in CourseSectionLists"
                                                :label="name"
                                                @update:checked="checkSomeSection"
                                                :checked="checkAllSection"
                                                :value="value.type+':'+name"
                                            /> -->
                                            <CRow class="justify-content-center">
                                                <CCol col=2 class="text-left">
                                                    <CInputCheckbox v-bind:key="name" v-for="(value, name) in CourseSectionLists"
                                                        :label="name"
                                                        @update:checked="checkSomeSection"
                                                        :checked="checkAllSection"
                                                        :value="value.type+':'+name"
                                                    />
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                        </CRow>
                                    </CCard>
                                </CCol>
                            </CCollapse>
                            <CCollapse :show="cardCollapseSectionNotSelected" >
                                <CCol sm="12">
                                    <CCard body-wrapper class="text-danger">
                                        <CRow>
                                            <CCol sm="12">
                                                <h6><CIcon :height="25" name="cil-warning"/>Section ยังไม่ถูกเลือก</h6>
                                            </CCol>
                                        </CRow>
                                    </CCard>
                                </CCol>
                            </CCollapse>
                            <CCollapse :show="cardCollapseSectionNotFound" >
                                <CCol sm="12">
                                    <CCard body-wrapper>
                                        <CRow>
                                            <CCol sm="12">
                                                <h5><CIcon :height="50" name="cil-x"/>ไม่พบกระบวนวิชาในสำนักทะเบียน</h5>
                                            </CCol>
                                        </CRow>
                                    </CCard>
                                </CCol>
                            </CCollapse>
                        </CCol>
                    </CRow>
                    <CAlert
                        color="warning"
                        closeButton
                        :show.sync="regNotSelectAlert"
                    >
                        กระบวนวิชายังไม่ถูกเลือก
                    </CAlert>
                    <CAlert
                        color="warning"
                        closeButton
                        :show.sync="sectionNotSelectAlert"
                    >
                        Section ยังไม่ถูกเลือก
                    </CAlert>
                    <!-- <CRow v-show="!loadingSections">
                        <CCol class="text-center">
                            <CButton @click="beforeCreateMoodleCourse" :disabled="false" color="pastelpurple2">สร้างคอร์ส</CButton>
                        </CCol>
                    </CRow> -->
                    <CCollapse :show="showCreateBtn">
                        <CRow>
                            <CCol class="text-center">
                                <CButton @click="beforeCreateMoodleCourse" color="pastelpurple2">สร้างคอร์ส</CButton>
                            </CCol>
                        </CRow>
                    </CCollapse>
                    <CRow class="pt-1">
                        <CCol class="text-center">
                            <CButton size="sm" @click="showSelectCurrentOrFutureTermFunc" color="outline-danger">ย้อนกลับ</CButton>
                        </CCol>
                    </CRow>
                <!-- </div> -->
                </CCollapse>
            </div>
            <div v-if="showCreateFutureTerm">
                <CCollapse :show="!loadRegSuccess">
                    <CRow>
                        <CCol col=12 class="text-center">
                            <div  class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </CCol>
                        <CCol col=12 class="text-center">
                            กำลังดึงข้อมูลจากสำนักทะเบียน
                        </CCol>
                    </CRow>
                </CCollapse>
                <!-- <div v-show="loadRegSuccess"> -->
                <CCollapse :show="loadRegSuccess" >
                    <CAlert
                        color="warning"
                        closeButton
                        :show.sync="usePastYearForFuterTermAlert"
                    >
                    ตอนที่ท่านกำลังสร้างคอร์สเทอม {{futureTerm}} โดยอ้างอิงกระบวนวิชาจาก {{pastYearForFutureTerm}} ซึ่งการสร้างในรูปแบบนี้จะยัง<b>ไม่มีการนำเข้านักศึกษาให้อัตโนมัติในทันที</b>
                    </CAlert>
                    <CAlert
                        color="warning"
                        :show.sync="optionPastYearForFuterTermAlert"
                    >
                    หากไม่พบกระบวนวิชาในเทอมที่เลือก สามารถ<a v-on:click="usePastYearForFutureTermFunc"><b>คลิกที่นี่</b></a>เพื่อเปิดคอร์สแบบอ้างอิงข้อมูลในปีก่อนหน้า
                    </CAlert>
                    <CRow>
                        <CCol >
                            <h5><b>เลือกกระบวนวิชาจากสำนักทะเบียน</b></h5>
                        </CCol>
                    </CRow>
                    <CRow >
                        <CCol class="text-center">
                            <!-- <CInput
                                type="number"
                                prepend="รหัสวิชา 6 หลัก"
                                description="เช่น 261214"
                                required
                                invalid-feedback="คุณกรอกรหัสวิชาไม่ถูกต้อง"
                                :is-valid="moodleCourseCodeValidator"
                            /> -->
                            <CSelect
                                :options="regCourseLists"
                                placeholder="เลือกกระบวนวิชา..."
                                @update:value="regCourseValidator"
                            />
                        </CCol>
                    </CRow>
                    <CRow v-show="loadingSections">
                        <CCol col=12 class="text-center">
                            <div  class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </CCol>
                        <CCol col=12 class="text-center">
                            กำลังค้นหาSection
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol class="text-center">
                            <CCollapse :show="cardCollapseSection" >
                                <CCol sm="12">
                                    <CCard body-wrapper>
                                        <CRow>
                                            <CCol sm="12">
                                                <h5>เลือก Section</h5>
                                            </CCol>
                                        </CRow>
                                        <!-- <span>Section ที่เลือก: {{ sectionCheckedVal }}</span> -->
                                        <!-- <li v-bind:key="name" v-for="(value, name) in CourseSectionLists">
                                            {{ name }}
                                        </li> -->
                                        <CRow>
                                            <CCol sm="12">
                                            <CInputCheckbox
                                                label="เลือกทั้งหมด"
                                                @update:checked="checkAllSectionFunc"
                                                :checked="checkAllSection"
                                                value="CheckAkk"
                                            />
                                            <!-- <CInputCheckbox v-bind:key="name" v-for="(value, name) in CourseSectionLists"
                                                :label="name"
                                                @update:checked="checkSomeSection"
                                                :checked="checkAllSection"
                                                :value="value.type+':'+name"
                                            /> -->
                                            <CRow class="justify-content-center">
                                                <CCol col=2 class="text-left">
                                                    <CInputCheckbox v-bind:key="name" v-for="(value, name) in CourseSectionLists"
                                                        :label="name"
                                                        @update:checked="checkSomeSection"
                                                        :checked="checkAllSection"
                                                        :value="value.type+':'+name"
                                                    />
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                        </CRow>
                                    </CCard>
                                </CCol>
                            </CCollapse>
                            <CCollapse :show="cardCollapseSectionNotSelected" >
                                <CCol sm="12">
                                    <CCard body-wrapper class="text-danger">
                                        <CRow>
                                            <CCol sm="12">
                                                <h6><CIcon :height="25" name="cil-warning"/>Section ยังไม่ถูกเลือก</h6>
                                            </CCol>
                                        </CRow>
                                    </CCard>
                                </CCol>
                            </CCollapse>
                            <CCollapse :show="cardCollapseSectionNotFound" >
                                <CCol sm="12">
                                    <CCard body-wrapper>
                                        <CRow>
                                            <CCol sm="12">
                                                <h5><CIcon :height="50" name="cil-x"/>ไม่พบกระบวนวิชาในสำนักทะเบียน</h5>
                                            </CCol>
                                        </CRow>
                                    </CCard>
                                </CCol>
                            </CCollapse>
                        </CCol>
                    </CRow>
                    <CAlert
                        color="warning"
                        closeButton
                        :show.sync="regNotSelectAlert"
                    >
                        กระบวนวิชายังไม่ถูกเลือก
                    </CAlert>
                    <CAlert
                        color="warning"
                        closeButton
                        :show.sync="sectionNotSelectAlert"
                    >
                        Section ยังไม่ถูกเลือก
                    </CAlert>
                    <CCollapse :show="showCreateBtn">
                        <CRow>
                            <CCol class="text-center">
                                <CButton @click="beforeCreateMoodleCourse" color="pastelpurple2">สร้างคอร์ส</CButton>
                            </CCol>
                        </CRow>
                    </CCollapse>
                    <CRow class="pt-1">
                        <CCol class="text-center">
                            <CButton size="sm" @click="showSelectCurrentOrFutureTermFunc" color="outline-danger">ย้อนกลับ</CButton>
                        </CCol>
                    </CRow>
                <!-- </div> -->
                </CCollapse>
            </div>
        </div>
        <template #footer>
            <!-- <CButton @click="afterAcceptPolicy()" color="success">Accept</CButton> -->
            <CButton size="sm" @click="closeModal" color="secondary">Close</CButton>
        </template>
    </CModal>
</div>
</template>

<script>
import REGApi from "@/services/REGApi"
import MoodleApi from "@/services/MoodleApi"
import Swal from 'sweetalert2'
import _ from 'lodash'

export default {
    name: 'CreateNewMoodle',
    props:{
        openModal: Boolean
    },
    components: {
    },
    data () {
        return {
            // userEmail:'Dome.Potikanond@cmu.ac.th',
            // userEmail:'pawinee.nim@cmu.ac.th',
            userEmail:localStorage.getItem('email'),
            openCreateModal: false,
            optionsterm: [ { value: 'js', label: '(3/63) สร้างคอร์สเพื่อใช้งานในเทอมปัจจุบัน' }, { value: 'html', label: '(1/64)สร้างคอร์สล่วงหน้าเพื่อใช้งานในเทอมถัดไป' } ],
            options: [ { value: 'js', label: 'JavaScript' }, { value: 'html', label: 'HTML' } ],
            regCourseLists: ['Not found'],
            regCourseSelected: "",
            regCourseSelectedName: "",
            usePastYearForFuterTermAlert: false,
            optionPastYearForFuterTermAlert: true,
            regNotSelectAlert: false,
            sectionNotSelectAlert: false,
            cardCollapseSection: false,
            cardCollapseSectionNotSelected: false,
            cardCollapseSectionNotFound: false,
            loadingSections:false,
            checkAllSection: false,
            CourseSectionLists: [],
            sectionCheckedVal: [],
            loadRegSuccess:false,
            showCreateCurrentTerm: false,
            showCreateFutureTerm: false,
            showSelectCurrentOrFutureTerm: true,
            titleName: "สร้างคอร์สใหม่",
            reg_Term:process.env.VUE_APP_REG_TERM,
            reg_Year:process.env.VUE_APP_REG_YEAR,
            currentTerm: "",
            futureTerm: "",
            pastYearForFutureTerm: "",
            onSelectedTerm: "",
            showCreateBtn: false
        }
    },
    created() {
        this.calculateTerm()
        if(this.userEmail === "nuttapong.pongkam@cmu.ac.th"){
            this.userEmail = "arnan.s@cmu.ac.th"
        }
        // this.getTeacherCourseFromReg(this.userEmail)
    },
    watch: { 
        openModal: function(newVal, oldVal) { // watch it
            console.log('Prop changed: ', newVal, ' | was: ', oldVal)
            this.openCreateModal = newVal
        }
    },
    methods: {
        closeModal () {
            this.openCreateModal = false
            this.$emit('afterCloseModal', false);
        },
        async beforeCreateMoodleCourse () {
            if(this.regCourseSelected === ""){
                this.regNotSelectAlert = true
            }else{
                if(this.sectionCheckedVal.length == 0){
                    this.sectionNotSelectAlert = true
                }else{
                    this.regNotSelectAlert = false
                    this.sectionNotSelectAlert = false
                    console.log(this.regCourseSelected)
                    console.log(this.regCourseSelectedName)
                    console.log(this.sectionCheckedVal)
                    let cmuitaccount = localStorage.getItem('email')
                    let cmuitaccount_name = cmuitaccount.substring(0, cmuitaccount.lastIndexOf("@"))
                    let sectionJsonArr = []
                    for(let section of this.sectionCheckedVal){
                        console.log(section)
                        if(section.includes('lab')){
                            let sectionSplit = section.split(":")
                            let sectionJson = `000${sectionSplit[1]}`
                            sectionJsonArr.push(sectionJson)
                        }else if(section.includes('lec')){
                            let sectionSplit = section.split(":")
                            let sectionJson = `${sectionSplit[1]}000`
                            sectionJsonArr.push(sectionJson)
                        }
                    }
                    console.log("sectionJsonArr:",JSON.stringify(sectionJsonArr))
                    let termSplit = []
                    let timeStamp = {
                        start:"",
                        end:""
                    }
                    let moodleCategory = process.env.VUE_APP_MOODLE_THIS_CATEGORY
                    if(this.onSelectedTerm === this.pastYearForFutureTerm || this.onSelectedTerm === this.futureTerm){
                        termSplit = this.futureTerm.split("/")
                        timeStamp.start = process.env.VUE_APP_TIMESTAMP_START_NEXT_TERM
                        // timeStamp.start = `${Math.round(new Date().getTime()/1000)}`
                        timeStamp.end = process.env.VUE_APP_TIMESTAMP_END_NEXT_TERM
                        // console.log("timeStamp:",timeStamp)
                        moodleCategory = process.env.VUE_APP_MOODLE_NEXT_CATEGORY
                    }else{
                        termSplit = this.onSelectedTerm.split("/")
                        timeStamp.start = process.env.VUE_APP_TIMESTAMP_START_THIS_TERM
                        timeStamp.end = process.env.VUE_APP_TIMESTAMP_END_THIS_TERM
                    }
                    let apiData = ({
                        "term": termSplit[0],
                        "year": 25+termSplit[1],
                        "term_start": timeStamp.start,
                        "term_end": timeStamp.end,
                        "regCourse_selected_id": this.regCourseSelected,
                        "regCourse_selected_name": this.regCourseSelectedName,
                        "category_selected": moodleCategory,
                        "sectionArr": sectionJsonArr,
                        "create_by": {
                            "cmuitaccount_name": cmuitaccount_name,
                            "cmuitaccount": cmuitaccount,
                        }
                    })
                    this.createMoodleCourse(apiData)
                    // console.log(apiData)
                    // console.log(this.onSelectedTerm)
                }
            }
        },
        async createMoodleCourse(jsonData){
            if(jsonData){
                console.log("inprogress")
                this.openCreateModal = false
                this.afterCreateMoodleCourse('open','')
                let createMoodle = await MoodleApi.creatMoodleCourse(jsonData)
                console.log("response:",createMoodle)
                if(createMoodle){
                    if(createMoodle.errorcode){
                        console.log(createMoodle.errorcode)
                        this.afterCreateMoodleCourse(createMoodle.errorcode,createMoodle.message)
                    }else{
                        console.log("finish")
                        this.afterCreateMoodleCourse('success')
                    }
                }else{
                    console.log("fail")
                    this.afterCreateMoodleCourse('error','')
                }
            }
        },
        async afterCreateMoodleCourse(command,msg){
            let text = ""
            let title = ""
            let icon = ""
            let showLoading = false
            switch (command) {
                case 'open': 
                    // Swal.fire({
                    //     title: 'กำลังดำเนินการ',
                    // })
                    // Swal.showLoading()
                    title = "กำลังดำเนินการ"
                    showLoading = true
                    break;
                case 'success': 
                    // Swal.fire(
                    //     'เสร็จสิ้น',
                    //     'สร้างคอร์สเรียบร้อย ท่านสามารถเข้าสู่คอร์สได้ทันที<br><b>ระบบอาจใช้เวลาสักครู่ในการประมวลผลและนำเข้านักศึกษาให้ท่าน</b>',
                    //     'success'
                    // )
                    icon = "success"
                    title = "เสร็จสิ้น"
                    text = "สร้างคอร์สเรียบร้อย ท่านสามารถเข้าสู่คอร์สได้ทันที<br><b>ระบบอาจใช้เวลาสักครู่ในการประมวลผลและนำเข้านักศึกษาให้ท่าน</b>"
                    break;
                case 'error': 
                    // Swal.fire(
                    //     'เกิดข้อผิดพลาด',
                    //     'การสร้าง event เกิดข้อผิดพลาด',
                    //     'error'
                    // )
                    icon = "error"
                    title = "เกิดข้อผิดพลาด"
                    text = "การสร้าง event เกิดข้อผิดพลาด"
                    break;
                case 'shortnametaken': 
                    // Swal.fire(
                    //     'เกิดข้อผิดพลาด',
                    //     'มีคอร์สนี้อยู่แล้วในระบบ',
                    //     'error'
                    // )
                    icon = "error"
                    title = "เกิดข้อผิดพลาด"
                    text = "มีคอร์สนี้อยู่แล้วในระบบ"
                    break;
                default:
                    // Swal.fire(
                    //     'เกิดข้อผิดพลาด',
                    //     msg,
                    //     'error'
                    // )
                    icon = "error"
                    title = "เกิดข้อผิดพลาด"
                    text = msg
                    break;
            }
            
            Swal.fire({
                icon: icon,
                title: title,
                html: text,
                willClose: () => {
                    this.$emit('afterCreateMoodle', true)
                }
            })
            if(showLoading){
                Swal.showLoading()
            }
            // this.$emit('afterCreateMoodle', true);
        },
        regCourseValidator (val,event) {
            console.log(val)
            console.log(event)
            let valArr = val.split("|");
            this.regCourseSelected = valArr[0]
            this.regCourseSelectedName = valArr[1]
            this.regNotSelectAlert = false
            this.sectionNotSelectAlert = false
            this.showCreateBtn = false
            this.searchSection()
        },
        async searchSection () {
            console.log("searchSection")
            this.cardCollapseSectionNotSelected = false
            this.cardCollapseSection = false
            this.loadingSections = true
            // this.checkAllSectionFunc(true,"")
            // this.checkAllSectionFunc(false,"")
            this.checkAllSection = false
            this.sectionCheckedVal = []
            // if(this.regCourseSelected === ""){
            //     this.regNotSelectAlert = true
            // }else{
            //     let courseSections = await REGApi.getCourseSection(this.regCourseSelected)
            // }
            let termSplit = this.onSelectedTerm.split("/")
            let sectionList = await REGApi.getCourseSection(this.regCourseSelected, termSplit[0], "25"+termSplit[1])
            this.loadingSections = false
            if(sectionList.status === true){
                this.cardCollapseSectionNotFound = false
                this.cardCollapseSection = true
                // this.CourseSectionLists = sectionList.sections
                console.log("🚀 ~ searchSection ~ this.CourseSectionLists", sectionList.sections)
                console.log("🚀 ~ searchSection ~ this.CourseSectionLists", this.CourseSectionLists)
                let ordered = Object.keys(sectionList.sections).sort().reduce(
                    (obj, key) => { 
                        obj[key] = sectionList.sections[key]; 
                        return obj;
                    }, 
                    {}
                );
                console.log(ordered)
                this.CourseSectionLists = ordered
                // → '{"a":"baz","b":"foo","c":"bar"}'
                let newob = _.orderBy(sectionList.sections, 'name')
                console.log("🚀 ~ searchSection ~ newob", newob)
                for (let section in sectionList.sections) {
                    console.log("🚀 ~ searchSection ~ section", section)
                }
            }else{
                this.cardCollapseSection = false
                this.cardCollapseSectionNotFound = true
            }
        },
        async getTeacherCourseFromReg (email, term) {
            let termSplit = term.split("/")
            console.log("termSplit[0], '25'+termSplit[1]",termSplit[0], "25"+termSplit[1])
            console.log("userEmail",email)
            this.loadRegSuccess = false
            let teacherRegCourse = await REGApi.getTeacherCourse(email, termSplit[0], "25"+termSplit[1])
            this.regCourseLists = []
            if(teacherRegCourse){
                if(teacherRegCourse.status){
                    for(let i=0;i<teacherRegCourse.courses.length;i++){
                        // console.log(teacherRegCourse.courses[i])
                        let selectData = { value: `${teacherRegCourse.courses[i].no}|${teacherRegCourse.courses[i].title_long}`, label: `${teacherRegCourse.courses[i].no} - ${teacherRegCourse.courses[i].title_long}` }
                        if (this.regCourseLists.filter(function(e) { return e.value === `${teacherRegCourse.courses[i].no}|${teacherRegCourse.courses[i].title_long}`; }).length > 0) {
                            /* vendors contains the element we're looking for */
                            console.log('Duplicate')
                        }else{
                            this.regCourseLists.push(selectData)
                        }

                    }
                    // this.regCourseLists = teacherRegCourse.courses
                    console.log("getTeacherCourseFromReg:",teacherRegCourse)
                }else{
                    if(teacherRegCourse.message.includes("is not found")){
                        this.regCourseLists.push({value: '', label: `ข้อมูลของเทอม ${term} ยังไม่พบในสำนักทะเบียน`,disabled : true})
                    }
                }
                this.loadRegSuccess = true
            }
        },
        checkAllSectionFunc (event) {
            this.checkAllSection = event
            if(event){
                for(let section in this.CourseSectionLists){
                    let checkList = `${this.CourseSectionLists[section].type}:${section}`
                    console.log(checkList)
                    if(!this.sectionCheckedVal.includes(checkList)){
                        this.sectionCheckedVal.push(checkList)
                        console.log(this.sectionCheckedVal)
                    }else{
                        console.log("duplicate")
                    }
                }
            }else{
                for(let section in this.CourseSectionLists){
                    let checkList = `${this.CourseSectionLists[section].type}:${section}`
                    console.log(checkList)
                    if(this.sectionCheckedVal.includes(checkList)){
                        let index = this.sectionCheckedVal.indexOf(checkList);
                        if (index > -1) {
                            this.sectionCheckedVal.splice(index, 1);
                        }
                        console.log(this.sectionCheckedVal)
                    }else{
                        console.log("not found")
                    }
                }
            }
            if(this.sectionCheckedVal.length === 0){
                this.showCreateBtn = false
            }else{
                this.showCreateBtn = true
            }
        },
        checkSomeSection (event, val) {
            if(event){
                console.log(event,val.target.value)
                let checkList = val.target.value
                if(!this.sectionCheckedVal.includes(checkList)){
                    this.sectionCheckedVal.push(checkList)
                    console.log(this.sectionCheckedVal)
                }else{
                    console.log("duplicate")
                }
            }else{
                console.log(event,val.target.value)
                let checkList = val.target.value
                if(this.sectionCheckedVal.includes(checkList)){
                    let index = this.sectionCheckedVal.indexOf(checkList);
                    if (index > -1) {
                        this.sectionCheckedVal.splice(index, 1);
                    }
                    console.log(this.sectionCheckedVal)
                }else{
                    console.log("not found")
                }
            }
            if(this.sectionCheckedVal.length === 0){
                this.showCreateBtn = false
            }else{
                this.showCreateBtn = true
            }
        },
        showCreateCurrentTermFunc () {
            this.showSelectCurrentOrFutureTerm = false
            this.showCreateCurrentTerm = true
            this.titleName = `สร้างคอร์สใหม่ [${this.currentTerm}]`
            this.onSelectedTerm = this.currentTerm
            this.getTeacherCourseFromReg(this.userEmail, this.currentTerm)
            this.clearSection()
        },
        showCreateFutureTermFunc () {
            this.showSelectCurrentOrFutureTerm = false
            this.showCreateFutureTerm = true
            this.optionPastYearForFuterTermAlert = true
            this.usePastYearForFuterTermAlert = false
            this.titleName = `สร้างคอร์สใหม่ [${this.futureTerm}]`
            this.onSelectedTerm = this.futureTerm
            this.getTeacherCourseFromReg(this.userEmail, this.futureTerm)
            this.clearSection()
        },
        showSelectCurrentOrFutureTermFunc () {
            this.showCreateBtn = false
            this.cardCollapseSectionNotFound = false
            this.showCreateCurrentTerm = false
            this.showCreateFutureTerm = false
            this.showSelectCurrentOrFutureTerm = true
            this.titleName = "สร้างคอร์สใหม่"
            this.clearSection()
        },
        calculateTerm () {
            // this.reg_Term = process.env.VUE_APP_REG_TERM
            // this.reg_Year = process.env.VUE_APP_REG_YEAR
            this.currentTerm = `${this.reg_Term}/${this.reg_Year.slice(2, 4)}`
            console.log("reg_Term",this.reg_Term)
            this.reg_Term = parseInt(this.reg_Term)
            if(this.reg_Term < 3){
                this.futureTerm = `${this.reg_Term+1}/${this.reg_Year.slice(2, 4)}`
                this.pastYearForFutureTerm = `${this.reg_Term+1}/${parseInt(this.reg_Year.slice(2, 4))-1}`
            }else{
                this.futureTerm = `1/${parseInt(this.reg_Year.slice(2, 4))+1}`
                this.pastYearForFutureTerm = `1/${parseInt(this.reg_Year.slice(2, 4))}`
            }
        },
        usePastYearForFutureTermFunc () {
            this.titleName = `สร้างคอร์สใหม่ [${this.futureTerm}] โดยอ้างอิงจากปี [${this.pastYearForFutureTerm}]`
            this.optionPastYearForFuterTermAlert = false
            this.usePastYearForFuterTermAlert = true
            this.onSelectedTerm = this.pastYearForFutureTerm
            this.getTeacherCourseFromReg(this.userEmail, this.pastYearForFutureTerm)
        },
        clearSection () {
            // this.cardCollapseSectionNotSelected = true
            this.cardCollapseSection = false
            // this.checkAllSectionFunc(true,"")
            // this.checkAllSectionFunc(false,"")
            this.checkAllSection = false
            this.sectionCheckedVal = []
        }
    }
}
</script>
