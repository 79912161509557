import {LMS} from "@/axios.js"

LMS.defaults.timeout = 600000

export default {

    async getMoodleListByUser(cmuitaccount){
      const response = await LMS.get(`api/GetMoodleListByUser?cmuitaccount=${cmuitaccount}`)
      .then(res => {
        if (res.data) {
          // console.log("getCourseByOwner(API Service):",res.data);
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },
    async manualEnrollMoodle(data){
      console.log(data)
      const response = await LMS.post(`api/ManualEnrollMoodle`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
              console.log("ManualEnrollMoodle(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async manualSyncMoodle(data){
      console.log(data)
      const response = await LMS.post(`api/ManualSyncMoodle`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
              console.log("ManualSyncMoodle(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async updateMoodleCourse(data){
      console.log(data)
      const response = await LMS.post(`api/MoodleUseOldCourse`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
              console.log("MoodleUseOldCourse(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async creatMoodleCourse(data){
      console.log(data)
      const response = await LMS.post(`api/CreateMoodle`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
              console.log("creatMoodleCourse(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async getEnrollStatus(cmuitaccount){
      const response = await LMS.get(`api/GetEnrollStatus?cmuitaccount=${cmuitaccount}`)
      .then(res => {
        if (res.data) {
          // console.log("getCourseByOwner(API Service):",res.data);
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },
    async getEnrollStatusByLms(lmsType, courseId){
      const response = await LMS.get(`api/GetEnrollStatusByLms?lms_type=${lmsType}&course_id=${courseId}`)
      .then(res => {
        if (res.data) {
          console.log("getEnrollStatusByLms(API Service):",res.data);
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },

}