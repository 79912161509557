import {LMS} from "@/axios.js"

LMS.defaults.timeout = 600000

let regTerm = process.env.VUE_APP_REG_TERM
let regYear = process.env.VUE_APP_REG_YEAR

export default {

    async getTeacherCourse(cmuitaccount, term, year){
        cmuitaccount = cmuitaccount.substring(0, cmuitaccount.lastIndexOf("@"))
        let response = await LMS.get(`api/GetTeacherCourseFromReg?cmuitaccount=${cmuitaccount}&year=${year}&term=${term}`)
        .then(res => {
          if (res.data) {
            console.log("getTeacherCourse(API):",res.data);
            return res.data
          }
        })
        .catch(e => {
          console.log(e);
        });
        return response
    },
    async getCourseSection(courseCode, term, year){
        let response = await LMS.get(`api/GetSectionFromReg?year=${year}&term=${term}&course_code=${courseCode}`)
        .then(res => {
          if (res.data) {
            console.log("getCourseSection(API):",res.data);
            return res.data
          }
        })
        .catch(e => {
          console.log(e);
        });
        return response
    },

}