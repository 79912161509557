<template>
  <CCol sm="6" lg="4">
    <CCard color="pastelpurple2" body-wrapper text-color="white" class="card-event" >
      <CRow>
        <CCol col="12">
          <h5 id="text-cut"><b>{{moodleObject.fullname}}</b></h5>
        </CCol>
        <!-- <CCol col="2">
          <CDropdown
              color="transparent p-0"
              placement="bottom-end"
          >
            <template #toggler-content>
              <CIcon name="cil-settings"/>
            </template>
            <CDropdownItem @click="confirmDelete(moodleObject._id)">Delete</CDropdownItem>
          </CDropdown>
        </CCol> -->
        <CCol col="12" style="font-size: 16px;">
          <CBadge :color="getStatusBadge(studentEnrollStatus)">
            <i v-show="studentEnrollStatus === 'executing'" class="fas fa-sync-alt fa-spin"></i>
            <i v-show="studentEnrollStatus === 'queued'" class="fas fa-hourglass-half fa-spin"></i>
            <i v-show="studentEnrollStatus === 'retrying'" class="fas fa-sync-alt fa-spin"></i>
            <i v-show="studentEnrollStatus === 'failed'" class="fas fa-exclamation-triangle"></i>
            <i v-show="studentEnrollStatus === 'finished'" class="fas fa-check"></i>
            {{getStatusText(studentEnrollStatus)}}
          </CBadge>
          <!-- <a v-on:click="manualEnroll" v-if="studentEnrollStatus === 'failed' || studentEnrollStatus === 'retrying'">
            <CBadge color="dark"><CIcon name="cil-sync"/>try again</CBadge>
          </a>
          <a v-on:click="manualEnroll" v-else-if="studentEnrollStatus !== 'executing' && studentEnrollStatus !== 'queued'">
            <CBadge color="dark"><CIcon name="cil-sync"/>enroll again</CBadge>
          </a> -->
          <a v-on:click="manualSync" v-if="studentEnrollStatus === 'failed' || studentEnrollStatus === 'retrying'">
            <CBadge color="dark"><CIcon name="cil-sync"/>try again</CBadge>
          </a>
          <!-- <a v-on:click="manualSync" v-else-if="studentEnrollStatus !== 'executing' && studentEnrollStatus !== 'queued'">
            <CBadge color="dark"><CIcon name="cil-sync"/>sync</CBadge>
          </a> -->
        </CCol>
        <CCol col="12">
          <p><small><em>
            อัพเดทล่าสุดเมื่อ {{studentEnrollStatusLatestUpdate}}
          </em></small></p>
        </CCol>
        <!-- <CCol col="12" class="text-right" style="bottom: 5px;">
          <CButton @click="createEventModal = true" color="customblue2">ใช้งานคอร์สเดิม</CButton>
        </CCol> -->
      </CRow>
      <CRow>
        <CCol col="12" class="text-right" style="bottom: 25px;right:15px;z-index: 999; position:absolute">
          <a v-on:click="manualSync" v-if="studentEnrollStatus !== 'executing' && studentEnrollStatus !== 'queued' ">
            <CButton color="dark" style="border: 1px solid #ffffff;">Sync now<CIcon name="cil-sync"/></CButton>{{" "}}
          </a>
          <CButton @click="newtab(`${moodleLink}course/view.php?id=${moodleObject.id}`)" color="customblue2" style="border: 1px solid #ffffff;">Go to course<CIcon name="cil-chevron-right"/></CButton>
        </CCol>
      </CRow>
      <!-- <CRow>
        <CCol>
          <CBadge :color="getStatusBadge('inprogress')">
            {{studentEnrollStatus}}
          </CBadge>
        </CCol>
      </CRow> -->
      <!-- <CRow class="align-items-end">
        <CCol>
          <CButton @click="createEventModal = true" color="customblue2">ใช้งานคอร์สเดิม</CButton>
        </CCol>
      </CRow> -->
    </CCard>
  </CCol>
</template>

<script>
import Swal from 'sweetalert2'
import MoodleApi from "@/services/MoodleApi"

export default {
  name: 'CardsMoodle',
  props:{
    moodleObject: Object
  },
  data(){
    return {
        eventName: "",
        moodleLink:process.env.VUE_APP_MOODLE_URL,
        canvasLink:process.env.VUE_APP_CANVAS_URL,
        studentEnrollStatus: "กำลังนำเข้านักศึกษา 35%",
        studentEnrollStatusLatestUpdate: "ไม่ทราบ",
        intervalStatus: ""
        // eventDetail: moodleObject.eventName
    }
  },
  created() {
    // this.getCourseByOwner("arnan.s@cmu.ac.th")
    console.log("From Card Moodle:",this.moodleObject)
    this.getEnrollStatus()
    this.intervalStatus = setInterval(this.getStatus, 3000)
  },
  beforeDestroy() {
    clearInterval(this.intervalStatus)
  },
  methods: {
    getStatusBadge (status) {
      switch (status) {
        case 'executing': return 'primary'
        case 'queued': return 'primary'
        case 'retrying': return 'primary'
        case 'failed': return 'danger'
        case 'finished': return 'success'
        default: return 'secondary'
      }
    },
    getStatusText (status) {
      switch (status) {
        case 'executing': return 'กำลังนำเข้านักศึกษา'
        case 'queued': return 'กำลังรอเพื่อนำเข้านักศึกษา'
        case 'retrying': return 'กำลังนำเข้านักศึกษา'
        // case 'retrying': return 'เกิดข้อผิดพลาดกำลังดำเนินการแก้ไข'
        case 'failed': return 'เกิดข้อผิดพลาด'
        case 'finished': return 'นำเข้านักศึกษาสำเร็จ'
        default: return 'ไม่ทราบสถานะ'
      }
    },
    btnClicked (index) {
        console.log("sdfsdf")
        this.$router.push({path: `events/eventdetail?id=${index}`})
    },
    newtab(url){
      var win = window.open(url, '_blank');
      win.focus();
    },
    async getEnrollStatus () {
      let moodleStatus = await MoodleApi.getEnrollStatusByLms('kcmoodle', this.moodleObject.id)
      if(moodleStatus.api_status === "success"){
        this.studentEnrollStatus = moodleStatus.job_status
        this.studentEnrollStatusLatestUpdate = await this.timeThaiFormat(moodleStatus.latest_update_job)
        // console.log(this.timeThaiFormat(moodleStatus.latest_update_job))
      }
    },
    async timeThaiFormat (timestamp) {
      // let option = {year: 'numeric',month: 'long',day: 'numeric',weekday: 'long',hour: 'numeric',minute: 'numeric',second: 'numeric'}
      return new Date(timestamp).toLocaleString('th-TH')
    },
    getStatus () {
      // console.log("test")
      this.getEnrollStatus()
    },
    async manualEnroll () {
      // ! course_number is not provided from backed since 28/10/2564
      let cmuitaccount = localStorage.getItem('email')
      let cmuitaccount_name = cmuitaccount.substring(0, cmuitaccount.lastIndexOf("@"))
      let apiData = ({
          "kcmoodle_course_number": this.moodleObject.course_number,
          "kcmoodle_course_idnumber": this.moodleObject.idnumber,
          "kcmoodle_course_id": this.moodleObject.id,
          "create-kcmoodle": this.moodleObject.course_number,
          "create_by": {
            "cmuitaccount_name": cmuitaccount_name,
            "cmuitaccount": cmuitaccount
          }
      })
      Swal.fire({
        title: 'นำเข้านักศึกษาอีกครั้ง?',
        icon: 'warning',
        showCancelButton: true,
      }).then(async(result) => {
        if (result.isConfirmed) {
          let manualEnrollMoodle = await MoodleApi.manualEnrollMoodle(apiData)
          if(manualEnrollMoodle){
            console.log(manualEnrollMoodle)
          }
        }
      })
    },
    async manualSync () {
      let cmuitaccount = localStorage.getItem('email')
      let cmuitaccount_name = cmuitaccount.substring(0, cmuitaccount.lastIndexOf("@"))
      let apiData = ({
          "lms_type": "kcmoodle",
          "course_idnumber": this.moodleObject.idnumber,
          "course_id": this.moodleObject.id,
          "create_by": {
            "cmuitaccount_name": cmuitaccount_name,
            "cmuitaccount": cmuitaccount
          }
      })
      Swal.fire({
        title: 'Sync ข้อมูลจากสำนักทะเบียน?',
        icon: 'warning',
        showCancelButton: true,
      }).then(async(result) => {
        if (result.isConfirmed) {
          this.studentEnrollStatus = "queued"
          let manualSyncMoodle = await MoodleApi.manualSyncMoodle(apiData)
          if(manualSyncMoodle){
            console.log(manualSyncMoodle)
          }
        }
      })
    }
  }
}
</script>
